import axios from 'axios';
import { ElMessage } from 'element-plus';

const timeout = 10000;

// 添加请求拦截器
axios.interceptors.request.use((request) => {
  request.headers['token'] = localStorage.getItem('token') || '';
  return request;
});

axios.interceptors.response.use(
  (response) => {
    const { status, data } = response;
    if ((status >= 200 && status < 300) || status == 304) {
      if (data) {
        if (data.code === 999 || !data.code && !data.Result) {
          if (data.code) {
            ElMessage({
              message: data.msg,
              type: 'error',
              showClose: true,
              duration: 1000,
            });
          } else {
            ElMessage({
              message: data.Info,
              type: 'error',
              showClose: true,
              duration: 1000,
            });
          }
        }
        else if (data.code === 302 && localStorage.getItem('token')) {
          localStorage.removeItem('token')
          localStorage.removeItem('userCode')
          localStorage.removeItem('orgToken')
          ElMessage.warning({
            message: '身份已过期，请重新登陆',
            type: 'warning',
            customClass: 'el-msg',
          })
        }
        return data;
      } else {
        return response;
      }
    } else {
      ElMessage({
        message: '操作失败了,请重新尝试！',
        type: 'error',
        showClose: true,
        duration: 1000,
      });
      throw response;
    }
  },
  (error) => {
    ElMessage({
      message: '操作失败了,请重新尝试！',
      type: 'error',
      showClose: true,
      duration: 1000,
    });
    throw error;
  }
);

/**
 * POST提交
 * @param {string} url           地址路径
 * @param {string} data          提交的数据
 * @param {string} contentType   Content-Type 默认值为：application/json; charset=UTF-8   上传文件的时候请用multipart/form-data
 * @param {string} baseURL       基础地址路径
 * @param {string} ex            扩展值
 * @param {string} outTime       超时时间
 * @param {function} onUploadProgress 上传处理进度事件
 */
export async function post<T>(
  url: string,
  data: T,
  ex = localStorage.getItem('ex'),
  contentType = 'application/json',
  baseURL = '',
  outTime = timeout,
  onUploadProgress?: ((progressEvent: any) => void) | undefined
) {
  
  if (!baseURL && process.env.NODE_ENV === 'development') {
    if (window.Config) {
      // baseURL = `${window.Config.Host}${window.Config.Port?window.Config.Port+':':''}`;
      baseURL = '/';
    }
  }

  return axios({
    method: 'post',
    baseURL: baseURL,
    timeout: outTime,
    url,
    data: data,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': contentType,
      'Ex-': ex,
    },
    onUploadProgress,
  });
}

export async function RTMPost<T>(
  url: string,
  data: T,
  isRTM = true,
  ex = localStorage.getItem('ex'),
  contentType = 'application/json',
  rtmBaseURL = '',
  outTime = timeout,
  onUploadProgress?: ((progressEvent: any) => void) | undefined
) {
  const orgToken = self.localStorage.getItem('orgToken')
  if (!rtmBaseURL) {
    if (window.Config) {
      rtmBaseURL = `${window.Config.RTMUseSSL ? 'https' : 'http'}://${window.Config.RTMHost
        }:${isRTM ? window.Config.RTMPort : window.Config.URTMPort}${isRTM ? '/FJRH.RTM/' : orgToken?'/'+orgToken+'/V4/FJRH.Campus/':'/FJRH.Campus/'}`;
    }
  }
  return post(
    url,
    data,
    ex,
    contentType,
    rtmBaseURL,
    outTime,
    onUploadProgress
  );
}

/**
 * GET 获取数据
 * @param {string} url          地址路径
 * @param {string} data         提交的数据
 * @param {string} baseURL      基础地址路径
 * @param {string} responseType 表示服务器将响应的数据类型
 * @param {string} timeoutTime  超时时间
 */
export async function get(
  url: string,
  params: any,
  baseURL = '',
  responseType: any = 'json',
  outTime = timeout
) {
  if (!baseURL && process.env.NODE_ENV === 'development') {
    if (window.Config) {
      baseURL = `${window.Config.UseSSL ? 'https' : 'http'}://${window.Config.Host}${window.Config.Port?':'+window.Config.Port:''}/`;
      // baseURL ='/';
    }
  }

  return axios({
    method: 'get',
    baseURL: baseURL,
    timeout: outTime,
    responseType: responseType,
    url,
    ...(params ? { params: params } : {}), // get 请求时带的参数
  });
}

export async function RTMGet(
  url: string,
  params: any,
  isRTM = true,
  rtmBaseURL = '',
  responseType: any = 'json',
  outTime = timeout
) {
  const orgToken=self.localStorage.getItem('orgToken')
  if (!rtmBaseURL) {
    if (window.Config) {
      rtmBaseURL = `${window.Config.RTMUseSSL ? 'https' : 'http'}://${window.Config.RTMHost
        }:${isRTM ? window.Config.RTMPort : window.Config.URTMPort}${isRTM ? '/FJRH.RTM/' :  orgToken?'/'+orgToken+'/V4/FJRH.Campus/':'/FJRH.Campus/'}`;
    }
  }
  return get(url, params, rtmBaseURL, responseType, outTime);
}

// 获取网站可配置数据文件
export async function GetDocData(url:any) {
  return axios({
    method: "get",
    baseURL: url,
    data:{}
  })
  
}