<template>
  <el-image
    class="cover-image border"
    :src="src"
    :style="[
      width ? { width: width + 'px' } : {},
      height ? { height: height + 'px' } : {}
    ]"
  >
    <template #error>
      <img class="default-img" :src="defaultLiveCover" />
    </template>
  </el-image>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: ""
    },
    width: {
      type: Number
    },
    height: {
      type: Number
    }
  },
  data() {
    return {
      defaultLiveCover: require("@/assets/imgs/default_live_cover.png")
    };
  }
};
</script>

<style lang="scss">
.cover-image {
  width: 100%;
  border-radius: 4px 4px 0px 0px;
  &.border {
    border: 1px solid #ddd;
  }
  .default-img {
    width: 100%;
    height: 100%;
    border-radius: 4px 4px 0px 0px;
    object-fit: fill;
    box-sizing: border-box;
  }
}
</style>
