import { RTMPost } from '@/scripts/http';
interface DISParam {
  "rtmId": string,
  "name": string,
  "phone": string,
  "userCode": string,
  "type": number
}

export default {
  ServiceURL: "/CourseService/",
  //验证机构信息
  async CreateUserByRTM(data: DISParam) {
    const res = await RTMPost(`${this.ServiceURL}CreateUserByRTM`, { ...data,},false);
      return res;
  }
}
