<template>
  <div class="top-bar">
    <div class="inner">
      <div class="logo">
        <img src="../../assets/images/school_logo.png" />
      </div>
      <div class="bar-right">
        <el-menu
          :default-active="$route.meta.path || $route.path"
          :router="true"
          class="plat-menu"
          mode="horizontal"
          active-text-color="#F27200"
        >
          <el-menu-item index="/">首页</el-menu-item>
          <el-menu-item index="/live/list">在线课堂</el-menu-item>
          <el-menu-item index="/appointment">课堂预约</el-menu-item>
          <el-menu-item index="/rec/list/1">网络课堂</el-menu-item>
          <el-menu-item index="/teachers/list">名校名师</el-menu-item>
        </el-menu>
        <el-input
          v-model="kw"
          class="like-input"
          placeholder="搜索感兴趣的课程"
          size="small"
          @keyup.enter="search"
        >
          <template v-slot:suffix>
            <i class="el-input__icon el-icon-search" @click="search"></i>
          </template>
        </el-input>
        <!-- 登陆区域 -->

        <div v-if="isLogin" class="avatar-container" @click="ToMyappointment">
          <span class="avatar-wrapper">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link flex">
                <el-avatar :size="32" :src="avatar" @error="errorHandler">
                  <img
                    src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
                  />
                </el-avatar>
                <span class="user-name">{{ name }}</span>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    v-for="(item, index) in dropList"
                    :key="index"
                    @click="dropChange(item.type)"
                    >{{ item.name }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </span>
        </div>
        <div v-else class="loginbut">
          <!-- <span>查看更多资源？&nbsp;</span> -->
          <span class="login" @click="tologin()">登录/注册</span>
          <!-- <span class="separate">|</span>
          <span @click="tologin(2)">注册</span>-->
          <Logindialog ref="Login"></Logindialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logindialog from "./components/LoginDialog";
import apiUser from "@/api/user";
import { mapState } from "vuex";
import { ElMessage } from "element-plus";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    Logindialog,
  },
  data() {
    return {
      avatar: "",
      name: "Admin",
      isLogin: false,
      kw: this.$route.params.kw,
      dropList: [
        {
          name: "个人中心",
          type: 1,
        },
        {
          name: "退出登录",
          type: 2,
        },
      ],
    };
  },
  watch: {
    $route() {
      if (this.$route.path.indexOf("/search") === -1) {
        this.kw = "";
      }
    },
  },
  computed: {
    ...mapState(["userinfo"]),
  },
  created() {
    this.Getmessage();
  },
  methods: {
    search() {
      if (this.kw) {
        // this.$store.commit("setInputValue", this.kw);
        this.$router.push({
          name: "search",
          params: {
            kw: this.kw,
          },
        });
        // this.$router.push(
        //   { path: "/search" },
        //   onComplete => {},
        //   onAbort => {}
        // );
      } else {
        if (this.$route.path.indexOf("/search") > -1) {
          // this.$router.push({
          //   name: "search",
          //   params: {
          //     kw: ""
          //   }
          // });
        }
      }
      // this.kw = "";
    },
    // ToMyappointment() {},
    tologin() {
      this.$refs.Login.digShow();
    },
    dropChange(type) {
      switch (type) {
        case 1:
          window.open("/teacenter", "_blank");
          break;
        case 2:
          localStorage.removeItem("token");
          localStorage.removeItem("orgToken");
          this.$router.go(0);
          break;
        default:
          break;
      }
    },
    //获取个人信息
    async Getmessage() {
      if (localStorage.getItem("token")) {
        const res = await apiUser.GetDetails({});
        console.log(res, "头");
        if (res.code === 302) {
          // localStorage.removeItem('token')
          // ElMessage.warning({
          //   message: '身份已过期，请重新登陆',
          //   type: 'warning',
          //   customClass: 'el-msg',
          // })
          this.$refs.Login.digShow();
        } else {
          this.$store.commit("getMessage", res.data);
          this.isLogin = true;
          this.name = this.userinfo.name;
          this.avatar = this.userinfo.avatar;
        }
      }
    },
    //头像加载失败
    errorHandler() {
      return true;
    },
  },
});
</script>

<style lang="scss">
@import "../../assets/styles/base.scss";
.el-menu.el-menu--horizontal.plat-menu {
  border-bottom: none;
  > .el-menu-item,
  > .el-submenu .el-submenu__title {
    padding: 0;
    margin: 0 40px;
  }
  > .el-menu-item:not(.is-disabled) {
    border-bottom: none;
    // &.is-active {
    //   border-bottom: none;
    // }
  }
}

.el-input.like-input {
  width: 240px;
  .el-input__inner {
    border-radius: 16px;
  }
  .el-input__suffix {
    width: 30px;
    right: 0;
    padding-right: 5px;
    box-sizing: border-box;
    cursor: pointer;
  }
  .el-input__icon {
    color: #999;
    font-size: 16px;
  }
}

.user-dropdown.el-popper[x-placement^="bottom"] {
  margin-top: 0;
}
.user-dropdown.el-popper {
  width: 124px;
  padding: 4px 0;
  border-radius: 0px 0px 2px 2px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
  .popper__arrow {
    display: none;
  }
}
</style>
<style lang="scss" scoped>
@import "../../assets/styles/base.scss";
.top-bar {
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #fff;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.05);
  .inner {
    width: 1200px;
    height: 100%;
    display: flex;
    margin: 0 auto;
    box-sizing: border-box;
    justify-content: space-between;
    .logo {
      width: 140px;
      // padding-top: 15px;
      img {
        width: 100%;
      }
    }
  }
}
.bar-right {
  height: 60px;
  line-height: 60px;
  display: flex;
  align-items: center;

  .avatar-container {
    height: 100%;
    .avatar-wrapper {
      display: inline-flex;
      align-items: center;
      height: 100%;
      margin-left: 40px;
      cursor: pointer;
      .user-name {
        margin: 0 10px;
      }

      .el-icon-caret-bottom,
      .user-name,
      .icon_default_avatar {
        cursor: pointer;
      }
    }
  }
  .loginbut {
    margin-left: 50px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 60px;
    cursor: pointer;
    // .login {
    //   color: #f27200;
    //   text-decoration: underline;
    // }
  }
}
</style>
