import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus';
import locale from 'element-plus/lib/locale/lang/zh-cn';
import 'element-plus/lib/theme-chalk/index.css';
import "@/assets/fonts/iconfont.css";
import "@/assets/fonts/iconfont.js";
import "@/assets/styles/index.scss";

import DAvatar from "@/components/Avatar/Avatar.vue";
import DCover from "@/components/Cover/Cover.vue";
import { ElMessage } from 'element-plus'
//假
import SvgIcon from "@/components/SvgIcon/index.vue"; // svg component
import FileTag from "@/components/FileTag/index.vue"; // svg component
//假↑
const app = createApp(App);
app.component("d-avatar", DAvatar)
app.component("d-cover", DCover)
app.component("svg-icon", SvgIcon);
app.component("file-tag", FileTag);
app.use(store).use(router).use(ElMessage).use(ElementPlus, { locale }).mount('#app')
