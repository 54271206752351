import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/index.vue')
  },
  {
    path: '/live',
    redirect: "/live/list",
    component: () => import("../views/live/index.vue"),
    name: 'Live',
    children: [
      {
        path: "list",
        component: () => import("../views/live/list/index.vue")
      },
      {
        path: "detail/:id/:isFake",
        component: () => import('../views/live/live-detail/index.vue')
      }
    ],
  },
  {
    path: "/appointment",
    component: () => import("@/views/appointment/index.vue")
  },
  {
    path: "/teacenter",
    component: () => import("@/views/teacenter/index.vue")
  },
  ///假
  {
    path: "/rec/list/:type",
    component: () => import("@/views/rec/list.vue")
  },
  {
    path: '/rec',
    redirect: "/rec/list",
    name: 'rec',
    component: () => import("../views/rec/index.vue"),
    children: [
      {
        path: "/rec/list/:type",
        component: () => import("@/views/rec/list.vue")
      },
      {
        path: "/rec/detail/:id?",
        component: () => import('@/views/rec/detail/rec-detail.vue')
      },
      {
        path: "/rec/mooc/:id?",
        component: () => import('@/views/rec/detail/rec-mooc.vue')
      },
      {
        path: "/rec/mooc-detail/:courseId/:actionId",
        component: () => import('@/views/rec/detail/mooc-detail.vue')
      },
    ],
  },
  {
    path: '/teachers',
    redirect: "/teachers/list",
    name: 'teachers',
    component: () => import("../views/teachers/index.vue"),
    children: [
      {
        path: "/teachers/list",
        component: () => import("@/views/teachers/list/index.vue")
      },
      {
        path: "/teachers/introduce/:id/:isFake",
        component: () => import('@/views/teachers/introduce/index.vue')
      }
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to: any, from: any, next: any) => {
  next()
})
export default router
