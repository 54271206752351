
import rtmUser from "@/api/RTMuser";
import apiUser from "@/api/user";
import disSchool from "@/api/DISSchool";
import Org from "@/api/org";
import CryptoJS from "crypto-js";
import { defineComponent } from "vue";
// import { async } from '../../../scripts/http';
export default defineComponent({
  props: {},
  data() {
    const phloginFrom = {
      // 登录注册表单
      // mobile: "17759501791", //hqm
      mobile: "", //手机号
      code: "", //短信验证码
      pcode: "",
      // account: "17759501791", //hqm
      account: "", //账号
      // password: "q123456", //hqm
      password: "", //账号密码
      npassword: "", //密码确认
      agreement: false,
    };
    // 校验手机
    const checketem = (
      rule: any,
      value: string,
      callback: (arg1?: Error) => void
    ) => {
      if (this.isGetcode) {
        return callback();
      }
      callback(new Error("请输入正确的电话"));
    };
    const validatePass = (
      rule: any,
      value: string,
      callback: (arg1?: Error) => void
    ) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== phloginFrom.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      Logindialog: false,
      phloginFrom: phloginFrom,
      orgFrom: {
        schoolname: "",
        schoolcode: "",
        identity: "1",
        name: "",
        schoolID: "",
      },
      loginRes: [],
      loginRules: {
        mobile: [{ validator: checketem, trigger: "none" }],
        password: [{ required: true, message: "请设置密码", trigger: "blur" }],
        npassword: [{ validator: validatePass, trigger: "blur" }],
      },
      orgRules: {
        schoolName: [
          {
            required: true,
            message: "请输入学校名称",
            trigger: ["change", "blur"],
          },
        ],
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: ["change", "blur"],
          },
        ],
        schoolID: [
          {
            required: true,
            message: "请输入教工号或学号",
            trigger: ["change", "blur"],
          },
        ],
      },
      schoolList: [] as any[], //已绑定的后端返回教师列表
      orgList: [] as any[], //区县所有学校列表
      phoneLogin: true, //手机登陆
      dropdowntext: "今天内有效",
      countdown: 60,
      logintype: 1, //1登录表单 2设置密码表单  其他 绑定机构表单
      isGetcode: false, // 是否发送了短信验证码
      isPasscode: false, // 是否登录成功
      Sindex: 0, //列表序列号
      pcodesrc: "",
      secschool: "", //选择的学校
      token: "",
      rtmID: "",
      ispcodesrc: true, //验证码获取
    };
  },
  async created() {
    const res = await Org.GetOrg();
    console.log(res);
  },

  methods: {
    //图片验证码
    async GetpicCode() {
      const res = await rtmUser.GetValidateCode();
      if (res.Result) {
        this.ispcodesrc = true;
        this.pcodesrc = "data:image/png;base64," + res.Data.Data;
        localStorage.setItem("ex", res.Data.ID);
      } else {
        this.ispcodesrc = false;
      }
      // this.pcode = ''
    },
    //切换表单
    change() {
      this.phoneLogin = !this.phoneLogin;
      (this.$refs.loginFromref as HTMLFormElement).resetFields();
    },
    //模态框显示
    digShow() {
      this.Logindialog = true;
      this.GetpicCode();
    },
    //下拉
    handleCommand(command: string) {
      this.dropdowntext = command;
    },
    //短信验证码
    async Getcode() {
      const regMobile = /^1\d{10}$/;
      if (regMobile.test(this.phloginFrom.mobile)) {
        this.isGetcode = true;
        const res = await rtmUser.Send({ phone: this.phloginFrom.mobile });
        if (res.Result) {
          this.count60();
        }
      }
      (this.$refs.loginFromref as HTMLFormElement).validateField("mobile");
    },
    //计时器
    count60() {
      let count60Timeout = setTimeout(this.count60, 1000);
      // clearTimeout(count60Timeout) //清除计时器
      if (this.countdown > 0) {
        this.countdown--;
      }
      if (this.countdown == 0) {
        this.countdown = 60;
        this.isGetcode = false; //不显示60s计时器
        clearTimeout(count60Timeout); //清除计时器
        return; //结束方法的调用
      }
    },
    //  时间转为毫秒
    ChangeTime() {
      switch (this.dropdowntext) {
        case "今天内有效": {
          const now = new Date();
          return (
            (24 * 60 * 60 -
              (now.getHours() * 60 * 60 +
                now.getMinutes() * 60 +
                now.getSeconds())) *
            1000
          );
        }
        case "三小时内有效":
          return 3 * 60 * 60 * 1000;
        case "一周内有效":
          return 7 * 24 * 60 * 60 * 1000;
        default:
          break;
      }
    },
    //登录
    async login() {
      let res: any = {};
      if (this.phoneLogin) {
        res = await rtmUser.ValidatePhone({
          vCode: this.phloginFrom.code,
          phone: this.phloginFrom.mobile,
        });
      } else {
        res = await rtmUser.Login({
          user: this.phloginFrom.mobile,
          password: CryptoJS.MD5(this.phloginFrom.password).toString(),
          code: this.phloginFrom.pcode,
        });
        this.loginRes = res.Data.Orgs;
      }
      console.log(res, "初登录信息");
      if (res.Result) {
        this.token = res.Data.ID;
        this.rtmID = res.Data.User.UserID;
        if (res.Data.LoginStatus !== -1) {
          this.schoolList = [];
          if (res.Data.Orgs) {
            this.isPasscode = true;
            const resddd = await Org.GetOrg();
            let intersection: any = [];
            let arrMap: any = {};
            resddd.data.orgList.forEach(
              (item: any) => (arrMap[item.orgCode] = item)
            );
            res.Data.Orgs.forEach((item: any) => {
              // arrMap[item.ORGCode] && intersection.push(item);
              arrMap[item.ORGCode];
              intersection.push(item);
            });
            console.log("arrMap", intersection);
            intersection.forEach((element: any) => {
              const obj = {
                schoolname: element.ORGName,
                UserTypes: element.UserTypes,
                ORGCode: element.ORGCode,
              };
              this.schoolList.push(obj);
              this.secschool = this.schoolList[0].ORGCode;
            });
            console.log("学校列表", intersection);
          } else {
            this.addschool();
          }
        } else {
          this.logintype = 2;
        }
      }
    },
    // 切换学校
    changeSchool(item: any, index: number) {
      this.secschool = item.ORGCode;
      this.Sindex = index;
    },
    addschool() {
      this.logintype = 3;
      this.loadAll();
    },
    //登录首页
    async Toindex() {
      if (!this.orgFrom.schoolname) {
        localStorage.removeItem("orgToken");
      }
      const disres = await disSchool.CreateUserByRTM({
        rtmId: this.rtmID,
        name: this.orgFrom.name,
        phone: this.phloginFrom.mobile,
        userCode: this.orgFrom.schoolID,
        type: Number(this.orgFrom.identity),
      });
      if (disres.Result) {
        const rtmres = await rtmUser.BindOrgUser({
          rtmUserId: this.rtmID,
          name: this.orgFrom.name,
          orgToken: this.locationorgCode(),
          userCode: this.orgFrom.schoolID,
          userType: Number(this.orgFrom.identity),
          certId: this.token,
        });
        if (rtmres.Result) {
          this.secschool = this.locationorgCode();
          this.toshow();
        }
      }
    },
    //定位orgCode
    locationorgCode() {
      const index = this.orgList.findIndex(
        (r: any) => r.value === this.orgFrom.schoolname
      );
      if (index > -1) return this.orgList[index].orgCode;
    },
    //验证成功后
    async toshow() {
      const res = await apiUser.RTMlogin({
        expire: this.ChangeTime(),
        orgCode: this.secschool,
        token: this.token,
      });
      if (res.code === 200) {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("orgToken", res.data.orgCode);
        const arr = this.loginRes.filter(
          (item: any) => item.ORGCode === this.secschool
        )[0] as any;
        if (arr.OrgUser.UserCode) {
          localStorage.setItem("userCode", arr.OrgUser.UserCode);
        } else {
          localStorage.setItem("userCode", "");
        }
        this.$router.go(0);
      }
    },
    diaclose() {
      this.phoneLogin = true;
      if (this.isPasscode) {
        this.$router.go(0);
      }
    },
    // 带输入建议
    querySearch(queryString: any, cb: any) {
      let restaurants = this.orgList;
      console.log("带入建议", queryString);
      console.log("带入建议2", restaurants);
      // let results = queryString
      //   ? restaurants.filter(this.createFilter(queryString))
      //   : restaurants;
      let results = queryString
        ? (restaurants.filter(
            (item: any) => item.value.indexOf(queryString) !== -1
          ) as any)
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    // createFilter(queryString: any) {
    //   return (restaurant: any) => {
    //     return (
    //       restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
    //       0
    //     );
    //   };
    // },
    async loadAll() {
      const res = await Org.GetOrg();
      if (res.data.orgList) {
        this.orgList = [];
        res.data.orgList.forEach((element: any) => {
          const obj = {
            value: element.orgName,
            orgCode: element.orgCode,
          };
          this.orgList.push(obj);
        });
      }
    },
    handleSelect(item: any) {
      this.orgFrom.schoolcode = item.orgCode;
      localStorage.setItem("orgToken", item.orgCode);
    },
    handleChange() {
      let obj = this.orgList.filter(
        (item) => item.value === this.orgFrom.schoolname
      );
      if (!obj.length) {
        this.orgFrom.schoolname = "";
        localStorage.removeItem("orgToken");
      }
    },
    ///绑定密码
    Toaddschool() {
      (this.$refs.loginFromref as HTMLFormElement).validate(
        async (valid: boolean) => {
          if (valid) {
            const res = await rtmUser.SetPassword({
              rtmUserId: this.rtmID,
              pwd: CryptoJS.MD5(this.phloginFrom.password).toString(),
            });
            if (res.Result) {
              this.addschool();
            }
          } else {
            return false;
          }
        }
      );
    },
  },
});
