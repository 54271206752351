<template>
  <div id="app">
    <top-bar></top-bar>
    <div class="h60"></div>
    <div :class="$route.path !== '/' ? 'main-view' : 'main-none'">
      <router-view />
      <foot-bar></foot-bar>
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/TopBar/index";
import FootBar from "@/components/FootBar/index";
// import { apptList as liveApptList } from "./models/appointment";
// import { apptList as liveApptList } from "./models/appointment-class";
export default {
  components: {
    TopBar,
    FootBar,
  },
};
</script>
<style lang="scss">
.el-msg {
  z-index: 2020 !important;
}
</style>
<style lang="scss" scoped>
.h60 {
  height: 60px;
}

.main-view {
  height: calc(100vh - 60px);
  overflow-y: auto;
}
.main-none {
  height: calc(100vh - 60px);
}
</style>
