<template>
  <label
    class="file-tag"
    :class="'bg-' + vertType(fileExt)"
    :style="{
      width: size + 'px',
      height: size + 'px',
      'line-height': size + 'px',
      'font-size': size / 2 + 'px'
    }"
    ><svg-icon :icon-class="vertType(fileExt)"></svg-icon
  ></label>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 60
    },
    // 文件名后缀
    fileExt: {
      type: String,
      default: "xlsx"
    }
  },
  methods: {
    /**
     * 根据文件名后缀显示对应图标
     * @param type 文件名后缀
     */
    vertType(type) {
      switch (type) {
        case "xlsx":
        case "xls":
          return "excel";
        case "docx":
        case "doc":
          return "word";
        case "pdf":
          return "pdf";
        case "pptx":
        case "ppt":
          return "ppt";
        case "txt":
          return "txt";
        case "avi":
        case "mov":
        case "rmvb":
        case "flv":
        case "mp4":
        case "3gp":
          return "video";
        case "mp3":
        case "wma":
        case "wav":
        case "midi":
          return "audio";
        case "png":
        case "jpg":
        case "jpeg":
        case "gif":
        case "bmp":
          return "pic";
        case "rar":
        case "zip":
        case "7z":
        case "cab":
        case "arj":
        case "lzh":
        case "tar":
        case "gz":
        case "ace":
        case "uue":
        case "bz2":
        case "jar":
        case "iso":
        case "mpq":
          return "zip";
        // 练习类型
        case "practise":
          return "practise";
        default:
          return "other";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.file-tag {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 4px;
  font-size: 30px;
}

// 10种文件的背景颜色
.bg-video {
  background-color: rgba(251, 103, 61, 0.1);
}
.bg-pic {
  background-color: rgba(54, 209, 135, 0.1);
}
.bg-word {
  background-color: rgba(86, 134, 223, 0.1);
}
.bg-ppt {
  background-color: rgba(241, 155, 83, 0.1);
}
.bg-other {
  background-color: rgba(178, 178, 178, 0.1);
}
.bg-pdf {
  background-color: rgba(255, 114, 114, 0.1);
}
.bg-audio {
  background-color: rgba(151, 93, 226, 0.1);
}
.bg-excel {
  background-color: rgba(90, 204, 155, 0.1);
}
.bg-txt {
  background-color: rgba(92, 162, 255, 0.1);
}
.bg-zip {
  background-color: rgba(55, 156, 255, 0.1);
}
.bg-practise {
  background-color: rgba(54, 191, 196, 0.1);
}
</style>
