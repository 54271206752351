import { post } from '@/scripts/http';
const islogin = localStorage.getItem('token') ? 'auth' : "api";
interface User {
  "expire"?: number|undefined,
  "token"?: string,
  "orgCode"?: string
}

export default {
  ServiceURL: `/zone/rtm/${islogin}/user`,
  //RTM登录
  async RTMlogin(data: User) {
    const res = await post(`/zone/rtm/api/user/auth`, data);
    return res;
  },
  //获取个人信息
  async GetDetails(data: User) {
    const res = await post(`${this.ServiceURL}/view`, data);
    return res
  },
}
