import { RTMGet, RTMPost } from '@/scripts/http';
interface UserParam {
  "user"?: string,
  "phone"?: string,
  "password"?: string,
  "code"?: string,
  "rtmUserId"?: string,
  "name"?: string,
  "userCode"?: string,
  "userType"?: number,
  "orgToken"?: string,
  "from"?: number,
  "vCode"?:string,
  "certId"?:string,
  "pwd"?:string
}

export default {
  ServiceURL: "/UserService/",
  SServiceURL: "/SMSService/",
  //账号密码登录
  async Login(data: UserParam) {
    const res = await RTMPost(`${this.ServiceURL}Login`, { ...data, from: 60, orgToken: null });
    if (!res.status) {
      return res;
    }
  },
  /**
 * 手机短信登录
 */
  async ValidatePhone(data: UserParam) {
    const res = await RTMPost(`${this.SServiceURL}Validate`, { ...data, bCode: 500, orgToken: null });
    return res;
  },
  //获取图片验证码
  async GetValidateCode() {
    const res = await RTMGet(`/CommonService/GetValidateCode`, { codelength: 4 });
    return res
  },
  // 发送短信验证码
  async Send(data: UserParam) {
    const res = await RTMPost(`${this.SServiceURL}Send`, { ...data, bCode: 500, orgToken: null });
    return res
  },
  // 绑定密码
  async SetPassword(data: UserParam) {
    const res = await RTMPost(`${this.ServiceURL}SetPassword`, { ...data });
    return res
  },
  //绑定用户信息
  async BindOrgUser(data: UserParam) {
    const res = await RTMPost(`${this.ServiceURL}BindOrgUser`, { ...data });
    return res
  },
  //更新机构
  async UpdateName(data: UserParam) {
    const res = await RTMPost(`${this.ServiceURL}UpdateName`, { ...data });
    return res
  },
  //获取互动教室列表
  async GetHDRoomTree(data: UserParam) {
    const res = await RTMGet(`/ClsRoomService/GetHDRoomTree`, { ...data }, false);
    return res
  },
}
