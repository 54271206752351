<template>
  <div class="footer">
    <div class="inner">
      <div class="left">
        <div class="title">联系我们</div>
        <div class="con">
          福建睿和科技有限公司 福州市鼓楼区金牛山互联网产业园云座3号楼6层
          0591-83769623 400-918-8823
          <br />
          北京睿和国泰科技有限公司 北京市海淀区丰慧中路新材料创业大夏A座805
          010-62450956
        </div>
        <div class="copy-right">
          闽ICP备18002063号-1睿和科技-Copyright © 2017 睿和科技
        </div>
      </div>
      <div class="qrcode-wrap">
        <img src="../../assets/images/qrcode.jpg" />
        <span class="text">微信公众号</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 180px;
  background-color: #f0f0f0;
  .inner {
    width: 1200px;
    height: 100%;
    padding: 32px 0 0;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: black;
    box-sizing: border-box;
    .left {
      .title {
        font-size: 18px;
      }
      .con {
        margin-top: 24px;
        line-height: 24px;
      }
      .copy-right {
        margin-top: 24px;
        color: #b2b2b3;
      }
    }
    .qrcode-wrap {
      width: 100px;
      text-align: center;
      img {
        width: 100%;
      }
      .text {
        display: inline-block;
        margin-top: 13px;
      }
    }
  }
}
</style>
