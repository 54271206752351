<template>
  <el-image
    class="the-avatar"
    :src="src"
    :style="{ width: size + 'px', height: size + 'px' }"
  >
    <template #error>
      <img :src="defaultAvatar" />
    </template>
  </el-image>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 30
    },
    src: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      defaultAvatar: require("@/assets/imgs/default_avatar.png")
    };
  }
};
</script>

<style lang="scss">
.the-avatar {
  & img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: fill;
  }
}
</style>
