import { createStore } from 'vuex'

export default createStore({
  //数据存储属性
  state: {
    userinfo: "",//用户信息
  },
  //方法属性
  mutations: {
    getMessage(state, obj) {
      state.userinfo = obj
    },
  },
  //异步属性
  actions: {


    
  },
  //计算属性
  getters: {
  },
  modules: {
  }
})
