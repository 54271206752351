import { post } from '@/scripts/http';
// const islogin = localStorage.getItem('token') ? 'auth' : "api";
export default {
  ServiceURL: `/zone/sys/api/sys`,
  //获取机构列表
  async GetOrg() {
    const res = await post(`${this.ServiceURL}/org/list`,'');
    return res
  },

}
